@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.contents {
		margin-left: 0;
		margin-right: 0;
		
		&__footer {
			margin: 0 15px;
		}
	}

	
	/* bnr_index
	----------------------------------------------------------- */
	.bnr_index {
		margin: 0 15px 30px;
		text-align: center;
		display: flex;
		&__link {
			max-width: 370px;
		}
	}
	
	
	
	/* visual
	----------------------------------------------------------- */
	.visual {
		&__slider {
			&__item {
				position: relative;
				
				&__text {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					padding: 7px 10px;
					background: rgba(#043e3f, 0.85);
					color: $white;
					font-size: $xxs;
					font-weight: $font-medium;
					line-height: 1.5;
				}
			}
		}
		
		.slick-dotted.slick-slider {
			margin-bottom: 40px;
		}
		.slick-dots {
			bottom: -15px;
			font-size: 0;
			line-height: 0;
			
			li {
				width: 30px;
				height: 5px;
				
				button {
					width: 30px;
					height: 5px;
					padding: 0;
					
					&:before {
						content: '';
						width: 30px;
						height: 5px;
						line-height: 5px;
						font-size: 1px;
						opacity: 1;
						background: #ececec;
					}
				}
				
				&:hover,
				&.slick-active {
					button::before {
						background: $yellowLighten;
					}
				}
			}
		}
		
	}
	/* slide-bnr
	----------------------------------------------------------- */
	.index-bnr {
		padding: 30px 10px;
		background: #E7F2F2;
		&__slider{
			display: flex;
			flex-wrap: wrap;
		}
		&__item {
			width: calc(50% - 10px);
			margin: 10px 5px;
		}
		&__link {
			position: relative;
			padding-top: 65.77%;
			overflow: hidden;
			display: block;
			.__elem {
				width: 100%;
				@include centering-elements(true, false);
				top: 0;
			}
		}
	}

	/* student
	----------------------------------------------------------- */
	.student {
		position: relative;
		
		&::before,
		&::after {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: -1;
			content: '';
			height: 246px;
		}
		&::before {
			background: url(/common/img/index/bg_student.png) top center no-repeat;
			background-size: cover;
			opacity: 0.25;
		}
		&::after {
			background: url(/common/img/common/bg_dotted_white.png) 0 0 repeat;
			background-size: 4px;
		}
	}


	/* student
	----------------------------------------------------------- */
	.report {
		
		.selectbox {
			position: relative;
			z-index: 5;
			margin: 0 0 40px;

			&__select {
				position: relative;
				display: flex;
				align-items: center;
				padding: 0 15px;
				height: 48px;
				background: $white;
				border: 1px solid $borderColor;
				color: $textColor;
				cursor: default;
				
				&__text {
					flex: 1 1 auto;
				}
				.icon-angle-down {
					flex: 0 0 auto;
				}
				&.select-focus {
					.icon-angle-down {
						transform: rotate(180deg);
					}
				}
			}
			
			&__pulldown {
				display: none;
				position: absolute;
				left: 0;
				top: 48px;
				width: 100%;
				background: $baseColor;
				
				& > * {
					display: block;
					padding: 10px 15px;
					color: $white;
					
					&:not(:last-child) {
						border-bottom: 1px solid rgba($white, 0.15);
					}
					&.selected {
						background: #c4e9e9;
						color: $baseColorDarken;
					}
				}
			}
		}
	}	
	/* ============================================================ */
	/* Youtube */
	/* /youtube/ */
	/* ============================================================ */
	.youtube {
		&__ttl {
			text-align: left;
			font-size: $l;
			&__logo {
				margin: 0 0 10px 0;
				max-width: 120px;
				display: block;
			}
		}
		&__inner {
			background: #eee;
			padding: 15px 10px 0 10px;
			&__item {
				&__img {
					margin: 0 0 5px;
					display: block;
					position: relative;
					background: #000;
					& > img {
						opacity: 0.8;
					}
					&::after {
						@include centering-elements(true, true);
						content: "";
						display: block;
						width: 40px;
						height: 40px;
						background: url(/lp/feature/img/ico_play.png) 0 0 no-repeat;
						background-size: 40px;
					}
				}
				&__text {
					line-height: 1.5;
				}
			}
		}
	}
	
	.activities {
		max-width: 100%;
		padding: 0 15px 40px;
		
		&__box {
			background: url(/common/img/common/bg_graph_paper.png) 0 0 repeat #fff;
			background-size: 20px;
			padding: 30px;
			border: 1px solid $baseColor;
			
			.btn {
				width: 100%;
				margin: 0 0 10px;
				&:last-child {
					margin: 0;
				}
				.__ico {
					font-size: 3.4rem;
					margin: 0 10px 0 0;
				}
				.icon-tennis {
					font-size: 3.0rem;
				}
			}
		}
	}
}