@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	display: flex;
	flex-direction: column;
	padding-bottom: 45px;

	/* footer-sns
	----------------------------------------------------------- */
	.footer-sns {
		order: 2;
		display: flex;
		border-bottom: 1px solid $borderColor;
		
		&__item {
//			width: 25%;
			width: 50%;
			
			&:not(:first-child) {
				border-left: 1px solid $borderColor;
			}
			&__link {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: 75px;
				color: $textColor;
				font-size: 1.1rem;
				line-height: 1.5;
				
				.sns-btn {
					flex: 0 0 auto;
				}
			}
		}
	}


	/* fnav
	----------------------------------------------------------- */
	.fnav {
		order: 1;
		background: url(/common/img/common/bg_fnav.png) 0 0 repeat;
		background-size: 20px;
		color: $white;
		
		a {
			color: $white;
		}
		
		&__level1 {
			&__item {
				border-bottom: 1px solid rgba($white, 0.15);
				
				&__link {
					display: flex;
					align-items: center;
					padding: 15px;
					
					[class^="icon-angle-"] {
						margin: 0 8px 0 0;
					}
					&.active {
						.icon-angle-down {
							transform: rotate(180deg);
						}
					}
				}
			}
		}
		
		&__level2 {
			display: none;
			background: rgba(#000, 0.6);
			
			&__item {
				border-top: 1px dotted rgba($white, 0.3);
				
				&__link {
					display: block;
					padding: 15px 15px 15px 32px;
				}
			}
		}
		
		&__level3 {
			&__item {
				border-top: 1px dotted rgba($white, 0.3);
				&__link {
					display: block;
					padding: 15px 15px 15px 32px;
					
					&::before {
						content: '－';
						margin: 0 6px 0 0;
						color: #929191;
					}
				}
			}
		}
		
	}


	/* footer-content
	----------------------------------------------------------- */
	.footer-content {
		order: 3;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 25px 0;
		
		&__logo {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin: 0 0 10px;
			
			&__mark {
				width: 8vw;
				margin: 0 8px 0 0;
			}
			&__text {
				width: 28vw;
			}
		}
		&__address {
			margin: 0 0 15px;
			font-size: $xxs;
		}
		&__menu {
			display: flex;
			justify-content: center;
			margin: 0 0 20px;
			
			&__item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin: 0 5px;
				border: 1px solid $borderColor;
				color: $textColor;
				font-size: $xxxs;
				@include circle(60);
				
				&__icon {
					color: $baseColor;
					font-size: $l;
				}
			}
		}
		&__copyright {
			color: #8e8e8e;
			font-size: $xxxs;
		}
	}
	
}