@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.contents {
		max-width: none;
		padding: 0;
		
		&__footer {
			max-width: 1240px;
			margin: 0 auto;
			padding: 0 20px;
		}
	}
	.row {
		&__inner {
			max-width: 1240px;
			margin: 0 auto;
			padding: 0 20px;
		}
	}
	
	.news-list {
		padding-left: 5%;
		border-left: 1px solid #e4e0e0;
	}

	
	/* bnr_index
	----------------------------------------------------------- */
	.bnr_index {
		max-width: 1200px;
		margin: 0 auto 60px;
		padding: 0 20px;
		text-align: center;
		display: flex;
		
		&__link {
			max-width: 370px;
			display: inline-block;
		}
	}
	

	/* visual
	----------------------------------------------------------- */
	.visual {
		background: url(/common/img/common/bg_ttl01.png) 0 0 repeat;
		background-size: 88px;

		&__inner {
			max-width: 1200px;
			margin: 0 auto;
		}
		&__slider {
			width: 100%;
			
			&__item {
				position: relative;
				
				&__text {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					padding: 10px 20px;
					background: rgba(#043e3f, 0.85);
					color: $white;
					font-size: $xxxl;
					font-weight: $font-medium;
					line-height: 1.5;
				}
			}
		}
		
		.slick-dotted.slick-slider {
			margin-bottom: 75px;
		}
		
		.slick-dots {
			font-size: 0;
			line-height: 0;
			
			li {
				width: 50px;
				height: 5px;
				
				button {
					width: 50px;
					height: 5px;
					padding: 0;
					
					&:before {
						content: '';
						width: 50px;
						height: 5px;
						opacity: 1;
						background: #ececec;
					}
				}
				
				&:hover,
				&.slick-active {
					button::before {
						background: $yellowLighten;
					}
				}
			}
		}
	}

	/* slide-bnr
	----------------------------------------------------------- */
	.index-bnr {
		position: relative;
		background: #E7F2F2;
		padding: 70px 0;
		&__slider.slick-slider {
			max-width: 1220px;
			margin: 0 auto;
			padding: 0 50px;
		}
		&__item {
			margin-left: 10px;
			margin-right: 10px;
			width: calc((100% - 80px) / 4 );
			
		}
		&__link {
			position: relative;
			padding-top: 65.77%;
			overflow: hidden;
			&._opacity_ {
				display: block;
				&:hover {
					opacity: 0.7;
				}
			}
			.__elem {
				width: 100%;
				@include centering-elements(true, false);
				top: 0;
			}
		}
		.slick-arrow {
			z-index: 2;
			width: 20px;
			height: 50px;
			background: none;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.slick-next,
		.slick-prev {
			&::before {
				font-family: 'icomoon';
				font-size: 3.5rem;
				color: #888888;
				opacity: 1.0;
			}
			&:hover,
			&:focus {
				color: #000;
			}
		}

		.slick-next {
			right: 20px;
			&::before {
				content: "\e90f";
			}
		}
		.slick-prev {
			left: 20px;
			&::before {
				content: "\e910";
			}
		}
	}

	/* student
	----------------------------------------------------------- */
	.student {
		position: relative;
		
		&::before,
		&::after {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: -1;
			content: '';
			height: 420px;
		}
		&::before {
			background: url(/common/img/index/bg_student.png) top center no-repeat;
			background-size: cover;
			opacity: 0.25;
		}
		&::after {
			background: url(/common/img/common/bg_dotted_white.png) 0 0 repeat;
			background-size: 4px;
		}
	}
	
	
	/* report
	----------------------------------------------------------- */
	.report {
		
		&__filter {
			display: flex;
			justify-content: center;
			margin: 0 0 60px;
			
			&__item {
				position: relative;
				margin: 0 15px;
				padding: 0 0 5px;
				color: $textColor;
				
				&::before {
					position: absolute;
					left: 50%;
					bottom: 0;
					z-index: 5;
					content: "";
					width: 0;
					height: 3px;
					background: $baseColor;
					backface-visibility: hidden;
					transform: translateX(-50%);
					@include transition;
				}
				&.active,
				&:hover {
					text-decoration: none;
					
					&::before {
						width: 100%;
					}
				}
			}
		}
		
		
		.is-animated {
			animation: .6s fade-in;
		}
	}
	
	/* ============================================================ */
	/* Youtube */
	/* /youtube/ */
	/* ============================================================ */
	.youtube {
		&__ttl {
			font-size: $xxl;
			display: flex;
			align-items: center;
			&__logo {
				margin: 0 30px 0 0;
				max-width: 150px;
				display: block;
				&:hover {
					opacity: 0.7;
				}
			}
		}
		&__inner {
			background: #eee;
			padding: 20px 15px 5px 15px;
			&__item {
				max-width: none;
				&:nth-child(6) {
					display: none;
				}
				&__img {
					margin: 0 0 5px;
					display: block;
					position: relative;
					background: #000;
					& > img {
						opacity: 0.8;
					}
					&:hover {
						opacity: 0.7;
					}
					&::after {
						@include centering-elements(true, true);
						content: "";
						display: block;
						width: 50px;
						height: 50px;
						background: url(/common/img/common/ico_play.png) 0 0 no-repeat;
						background-size: 50px;
					}
				}
				&__text {
					line-height: 1.5;
				}
			}
		}
	}
	.activities {
		max-width: 1240px;
		margin: 0 auto;
		padding-right: 20px;
		padding-left: 20px;
		
		&__box {
			background: url(/common/img/common/bg_graph_paper.png) 0 0 repeat #fff;
			background-size: 20px;
			padding: 40px 20px;
			border: 1px solid $baseColor;
			display: flex;
			justify-content: center;
			
			.btn {
				width: calc((100% - 127px)/ 3);
				margin-left: 20px;
				margin-right: 20px;
				min-width: inherit!important;
				@media screen and (max-width: 1279.5px) {
					padding: 0 20px;
				}
				.__ico {
					font-size: 3.4rem;
					margin: 0 10px 0 0;
					@media screen and (max-width: 1279.5px) {
						margin: 0 5px 0 0;
					}
				}
				.icon-tennis {
					font-size: 3.0rem;
				}
			}
		}
	}
}