@charset "utf-8";
/* ============================================================ */
/* 進学実績 */
/* /career/ */
/* ============================================================ */
#career_index {
	
	.achievement {
		display: flex;
		align-items: center;
		color: $yellow;
		font-weight: $font-medium;
		line-height: 1.5;
		
		&__school {
			flex: 1 1 auto;
			font-size: $xl;
		}
		&__people {
			flex: 0 0 auto;
			margin: 0 0 0 20px;
			font-size: $l;
			white-space: nowrap;
			
			&__number {
				margin: 0 5px 0 0;
				font-size: 3.4rem;
				font-weight: 600;
			}
		}
	}	
	
}


/* ============================================================ */
/* 海外大学進学指導 */
/* /career/overseas.html */
/* ============================================================ */
#career_overseas {
	.column3 {
		.btn {
			width: 100%;
			min-width: auto !important;
			height: 100% !important;
			padding: 10px 20px;
			font-size: $m;
		}
	}
}

