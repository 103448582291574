@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	flex: 0 0 auto;
	background: $white;
	
	@media screen and (min-width: 1280px) {
		width: 255px;
		border-right: 1px solid $borderColor;
	}
	@media screen and (max-width: 1279.5px) {
		display: flex;
		align-items: center;
		height: 80px;
	}
	
	
	/* header-logo
	----------------------------------------------------------- */
	.header-logo {
		@media screen and (min-width: 1280px) {
			display: flex;
			align-items: center;
			height: 195px;
		}
		@media screen and (max-width: 1279.5px) {
			order: 2;
			margin-right: auto;
		}
		
		&__link {
			display: flex;
			align-items: center;
			
			@media screen and (min-width: 1280px) {
				flex-direction: column;
				width: 175px;
				margin: 25px auto 20px;
			}
			
			& > * {
				flex: 0 0 auto;
			}
		
			&__ribbon {
				position: relative;
				width: 126px;
				height: 18px;
				margin: 0 0 5px;
				background: #e13939;
				color: $white;
				font-size: $xxs;
				text-align: center;
				line-height: 18px;

				&::before,
				&::after {
					content: '';
					border-color: transparent;
					border-style: solid;
					border-width: 8px 6px;
					@include centering-elements(false, true);
				}
				&::before {
					left: 0;
					border-left-color: $white;
				}
				&::after {
					right: 0;
					border-right-color: $white;
				}
				@media screen and (max-width: 1279.5px) {
					display: none;
				}
			}
			&__mark {
				width: 63px;
				margin: 0 0 3px;
				
				@media screen and (max-width: 1279.5px) {
					width: 40px;
					margin: 0 15px 0 0;
				}
			}
			&__text {
				width: 180px;
				margin: 0 0 5px;
				
				@media screen and (max-width: 1279.5px) {
					width: 210px;
					margin: 0;
				}
			}
			&__copy {
				color: $textColor;
				line-height: 1.3;
				
				@media screen and (max-width: 1279.5px) {
					display: none;
				}
			}
		}
	}


	/* hnav
	----------------------------------------------------------- */
	.hnav {
		@media screen and (max-width: 1279.5px) {
			order: 1;
			margin: 0 20px 0 0;
		}
		.hnav__item--menu {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 80px;
			height: 80px;
			background: $baseColor;
			color: $white;
			font-size: 3.0rem;
			cursor: pointer;
			
			.hnav__item__text {
				display: none;
			}
			
			@media screen and (min-width: 1280px) {
				display: none;
			}
		}
	}


	/* gnav
	----------------------------------------------------------- */
	.gnav {
		@media screen and (max-width: 1279.5px) {
			position: absolute;
			top: 80px;
			bottom: 0;
			left: 0;
			z-index: 10;
			width: 255px;
			background: $white;
			border-right: 1px solid $borderColor;
			opacity: 0;
			visibility: hidden;
			transform: translateX(-100%);
			@include transition;
		}
		.show & {
			opacity: 1;
			visibility: visible;
			transform: translateX(0);
		}
		
		&__content1 {
			margin: 0 0 30px;
			border-top: 1px solid $borderColor;
			
			&__body {
				&:hover {
					.gnav__content1__item {
						background: $baseColor;
						color: $white;
					}
					.gnav__content1__lower {
						opacity: 1;
						visibility: visible;
					}
				}
			}
			&__item {
				display: block;
				padding: 15px 20px;
				border-bottom: 1px solid $borderColor;
				color: $baseColorDarken;
				cursor: pointer;
				@include transition;
				
				&.current,
				&:hover {
					background: $baseColor;
					color: $white;
				}
				.about &--about,
				.learning &--learning,
				.school &--school,
				.career &--career,
				.guide_hs &--hs,
				.guide_jhs &--jhs {
					background: $baseColor;
					color: $white;
				}
			}
			&__lower {
				position: absolute;
				top: 0;
				bottom: 0;
				z-index: 10;
				left: 255px;
				width: 260px;
				padding: 210px 0 20px;
				background: rgba($baseColor, 0.85);
				color: $white;
				opacity: 0;
				visibility: hidden;
				@include transition;
				
				@media screen and (max-width: 1279.5px) { padding: 20px; } 
				
				&__ttl {
					margin: 0 0 20px;
					font-size: $l;
					font-weight: $font-medium;
					
					&:not(:first-child) {
						margin-top: 50px;
					}
				}
				&__link {
					display: block;
					padding: 12px 20px;
					color: $white;
					line-height: 1.6;
					
					&__ornament {
						padding: 0 6px 0 0;
						color: rgba($white, 0.5);
						font-size: $xxs;
						transform: translateY(-2px);
					}
					&.current,
					&:hover {
						background: rgba($white, 0.8);
						color: $baseColorDarken;
						
						.gnav__content1__lower__link__ornament {
							color: rgba($baseColorDarken, 0.5);
						}
					}
				}
			}
		}
		
		&__content2 {
			margin: 0 20px 30px;
			
			&__item {
				display: flex;
				align-items: center;
				color: $textColor;
				font-size: $s;
				line-height: 1.6;
				
				.icon-angle-right {
					margin: 0 5px 0 0;
					color: $yellow;
				}
				
				&:not(:last-child) {
					margin: 0 0 10px;
				}
				&:hover {
					.gnav__content2__item__text {
						text-decoration: underline;
					}
				}
			}
		}
		
		&__content3 {
			margin: 0 20px;
		}
		
	}


	/* header-upper
	----------------------------------------------------------- */
	.header-upper {
		@media screen and (min-width: 1280px) {
			position: absolute;
			top: 0;
			left: 255px;
			right: 0;
			background: $white;
			box-shadow: 0 5px 5px rgba(#000, 0.05);
		}
		@media screen and (max-width: 1279.5px) {
			order: 3;
			padding: 0 20px 0 0;
		}

		&__inner {
			display: flex;
			align-items: center;
			
			@media screen and (min-width: 1280px) {
				max-width: 1240px;
				height: 80px;
				margin: 0 auto;
				padding: 0 20px;
			}
		}
		&__bnr {
			margin: 0 auto 0 0;
			max-width: 842px;
			display: flex;
			justify-content: space-between;
			
			@media screen and (max-width: 1279.5px) {
				display: none;
			}
			
			&__link {
				&--design {
					margin: 0 0 0 13px;
				}
				
				&--meiho {
					margin: 0 13px 0 0;
					max-width: 359px;
				}
			}
		}
		.sns-btn {
			flex: 0 0 auto;
			margin: 0 0 0 8px;
		}
	}
}