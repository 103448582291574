@charset "utf-8";
/* ============================================================ */
/* 1日の流れ */
/* /school/oneday.html */
/* ============================================================ */
#school_oneday {
	
	.day {
		
		&__content {
			
			&__balloon {
				display: flex;
				flex-direction: column-reverse;
				padding: 15px;
				background: url(/common/img/common/bg_snav.png) 0 0 repeat;
				background-size: 30px;
				color: $white;
				@include radius(4);
				
				&__img {
					flex: 0 0 auto;
				}
				&__body {
					flex: 0 0 auto;
					margin: 0 0 15px;
				}
				&__time {
					display: block;
					margin: 0 0 5px;
					color: #ffdf7a;
					font-size: $s;
					font-weight: $font-medium;
					line-height: 1.4;
				}
				&__ttl {
					margin: 0 0 10px;
					font-size: $xxxl;
					font-weight: $font-medium;
					line-height: 1.2;
				}
			}
			
			&:not(:last-child)::after {
				display: block;
				content: '';
				width: 32px;
				height: 37px;
				margin: 6px auto;
				background: url(/common/img/common/ico-arrow02.svg) 0 0 no-repeat;
				background-size: 32px auto;
			}
		}
	}

	
}

/* ============================================================ */
/* 部活動 */
/* /school/club/ */
/* ============================================================ */
.school_club {
	.__label {
		background: #c81138;
		width: 85px;
		height: 25px;
		color: $white;
		font-size: 1.2rem;
		line-height: 25px;
		line-height: 1.0;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: $font-medium;
		border: 2px solid $white;
	}
	.club-list {
		&__item {
			margin-bottom: 40px;
			position: relative;
			.__label {
				position: absolute;
				left: 3px;
				top: 3px;
				z-index: 1;
			}
		}
	}
	.ttl02 {
		&._activities_ {
			flex-direction: row;
			justify-content: center;
			.__label {
				margin: 0 10px 0 0;
			}
		}
	}
}

