@charset "utf-8";

$baseColor: #157c7d;
$baseColorLighten: #c4e9e9;
$baseColorDarken: #0d6e6f;
$textColor: #434343;
$borderColor: #e7e7e7;

$jhsBaseColor: #005979;
$hsBaseColor: #af1e26;

$white: #fff;
$yellowLighten: #d9b72b;
$yellow: #b39620;

$facebook: #3c57a2;
$twitter: #00acee;
$youtube: #e62117;
$line: #00b900;

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition {
	transition: 0.3s all;
}

@mixin opacity($opacity: 0.7) {
	opacity: $opacity;
}

@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}

@mixin literal {
	font-feature-settings: "palt";
	letter-spacing: 0.04em;
}

@keyframes fade-in {
	0% { opacity: 0.1; } 
	100% { opacity: 1; }
}	

@mixin hs-feature-text-shadow($color, $alpha: 0.3) {
	text-shadow: 0 0 6px rgba($color, $alpha),
								1px 0 6px rgba($color, $alpha),
								0 1px 6px rgba($color, $alpha), 
								1px 1px 6px rgba($color, $alpha),
								-1px 0 6px rgba($color, $alpha),
								0 -1px 6px rgba($color, $alpha),
								-1px -1px 6px rgba($color, $alpha);
}



/* font-size
-------------------------------------------------- */
$xxxs: 1.0rem;
$xxs: 1.2rem;
$xs: 1.3rem;
$s: 1.4rem;
$m: 1.6rem;
$l: 1.8rem;
$xl: 2.0rem;
$xxl: 2.2rem;
$xxxl: 2.4rem;


/* font-weight
-------------------------------------------------- */
$font-regular: 400;
$font-medium: 500;


