@charset "utf-8";

@import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import "_reset";
@import "_icon";
@import "_mixin";
@import "_lity";

@media print, screen and (min-width: 767.5px) {
	@import "pc/_base";
	@import "pc/_header";
	@import "pc/_contents";
	@import "pc/_footer";
	@import "pc/_index";
	@import "pc/_learning";
	@import "pc/_school";
	@import "pc/_career";
}

@media screen and (max-width: 767.499px) {
	@import "sp/_base";
	@import "sp/_header";
	@import "sp/_contents";
	@import "sp/_footer";
	@import "sp/_index";
	@import "sp/_learning";
	@import "sp/_school";
	@import "sp/_career";
}
@import "_utility";