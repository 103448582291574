@charset "utf-8";
/* ============================================================ */
/* 1日の流れ */
/* /school/oneday.html */
/* ============================================================ */
#school_oneday {
	
	.day {
		
		&__content {
			display: flex;
			
			&:last-child {
				.day__content__time::before,
				.day__content__time::after {
					display: none;
				}
			}
			
			&__time {
				flex: 0 0 auto;
				position: relative;
				margin: 0 20px 0 0;
				
				&__text {
					display: flex;
					align-items: center;
					justify-content: center;
					background: $baseColor;
					color: $white;
					font-size: $xxxl;
					@include circle(90);
				}
				&::before {
					top: 0;
					bottom: 0;
					z-index: -1;
					content: '';
					width: 8px;
					background: #d8f0f0;
					@include centering-elements(true, false);
				}

				&::after {
					bottom: 0;
					display: block;
					content: '';
					width: 50px;
					height: 42px;
					background: url(/common/img/common/ico-arrow01.svg) 0 0 no-repeat;
					background-size: 50px auto;
					@include centering-elements(true, false);
				}
			}
			
			&__balloon {
				flex: 1 1 auto;
				display: flex;
				margin: 0 0 30px;
				padding: 30px;
				background: url(/common/img/common/bg_snav.png) 0 0 repeat;
				background-size: 30px;
				color: $white;
				@include radius(4);
				
				&__img {
					flex: 0 0 auto;
					width: 46.6%;
					margin: 0 30px 0 0;
				}
				&__body {
					flex: 1 1 auto;
				}
				&__time {
					display: block;
					margin: 0 0 5px;
					color: #ffdf7a;
					font-size: $s;
					font-weight: $font-medium;
					line-height: 1.4;
				}
				&__ttl {
					margin: 0 0 10px;
					font-size: $xxxl;
					font-weight: $font-medium;
					line-height: 1.2;
				}
			}
		}
	}

	
}

/* ============================================================ */
/* 部活動 */
/* /school/club/ */
/* ============================================================ */
.school_club {
	.activities-bnr {
		a:hover img {
			opacity: 0.7;
		}
	}
	.__label {
		background: #c81138;
		width: 96px;
		height: 31px;
		color: $white;
		font-size: 1.5rem;
		line-height: 1.0;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: $font-medium;
		border: 2px solid $white;
	}
	.club-list {
		&__item {
			margin-bottom: 40px;
			position: relative;
			.__label {
				position: absolute;
				left: 3px;
				top: 3px;
			}
		}
	}
	.ttl02 {
		&._activities_ {
			flex-direction: row;
			justify-content: center;
			.__label {
				margin: 0 20px 0 0;
			}
		}
	}
}

