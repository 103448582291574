@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	margin: 0 15px 30px;
	
	&--column2 {
		.contents__inner {
			margin: 0 0 50px;
		}
		.main {
			margin-bottom: 40px;
		}
	}
}

.row {
	padding: 30px 15px;
	
	&--red {
		background: url(/common/img/common/bg_dotted_red.png) 0 0 repeat #fff6f6;
		background-size: 4px;
	}
	&--green {
		background: url(/common/img/common/bg_dotted_green.png) 0 0 repeat #dbf1f1;
		background-size: 4px;
	}
	&--green-wide {
		margin: 0 -15px;
		padding: 30px 15px;
		background: url(/common/img/common/bg_dotted_green.png) 0 0 repeat #dbf1f1;
		background-size: 4px;
	}
}



/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 20px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.lead {
	color: $yellow;
	font-size: $m;
	font-weight: $font-medium;
	text-align: center;
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	margin: 0 0 20px;
	background: url(/common/img/common/bg_ttl01.png) 0 0 repeat;
	background-size: 88px;
	color: #0d6e6f;
	font-size: $xxl;
	font-weight: $font-medium;
	line-height: 1.2;
	@include literal;
	
	&__body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 15px;
		height: 95px;
	}
	&__category-ttl {
		color: #676767;
		font-size: $s;
		font-weight: $font-regular;
	}
}

.ttl02 {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 0 30px;
	padding: 0 0 25px;
	color: $baseColorDarken;
	font-size: $xxxl;
	font-weight: $font-medium;
	text-align: center;
	line-height: 1.3;
	@include literal;
	
	&::after {
		bottom: 0;
		content: '';
		width: 60px;
		border-bottom: 2px solid $baseColor;
		@include centering-elements(true, false);
	}
	&__sub-ttl {
		color: $textColor;
		font-size: $s;
		font-weight: $font-regular;
	}
	
	&--red {
		color: #dd0000;
		
		&::after {
			border-bottom-color: #dd0000;
		}
	}
}

.ttl03 {
	position: relative;
	margin: 0 0 30px;
	font-size: $xxxl;
	font-weight: $font-medium;
	line-height: 1.4;
	text-align: center;
	@include literal;
	
	&::after {
		display: block;
		content: attr(data-text);
		margin: 5px 0 0;
		color: $baseColor;
		font-size: $xxs;
		font-weight: $font-regular;
		text-transform: uppercase;
		letter-spacing: 0;
	}
}

.ttl04 {
	margin: 0 0 30px;
	padding: 15px 0 0;
	border-top: 1px solid $baseColor;
	color: $baseColorDarken;
	font-size: $xl;
	font-weight: $font-medium;
	line-height: 1.4;
	@include literal;
	
	&.accordion,
	&.sp-accordion {
		display: flex;
		align-items: center;
		
		.icon-add {
			color: $baseColor;
		}
		&.active {
			.icon-add::before {
				content: "\e909";
			}
		}
	}
}


.ttl05 {
	margin: 0 0 20px;
	padding: 10px 15px;
	background: #e0f3f3;
	font-size: $l;
	font-weight: $font-medium;
	line-height: 1.4;
	@include literal;
	
	&.accordion,
	&.sp-accordion {
		display: flex;
		align-items: center;
		
		.icon-add {
			color: $baseColor;
		}
		&.active {
			.icon-add::before {
				content: "\e909";
			}
		}
	}
}

.ttl06 {
	margin: 0 0 20px;
	padding: 0 0 0 15px;
	border-left: 3px solid $baseColor;
	color: $baseColorDarken;
	font-size: $m;
	font-weight: $font-medium;
	line-height: 1.4;
	@include literal;
}

.ttl07 {
	position: relative;
	margin: 0 0 20px;
	padding: 20px 0 0;
	color: $baseColorDarken;
	font-size: $xxxl;
	font-weight: $font-medium;
	text-align: center;
	line-height: 1.4;
	@include literal;
	
	&::before {
		top: 0;
		display: block;
		content: '';
		width: 25px;
		height: 3px;
		background: $baseColor;
		@include centering-elements(true, false);
	}
}

.ttl08 {
	margin: 0 0 15px;
	color: #ffdf7a;
	font-size: $xl;
	font-weight: $font-medium;
	text-align: center;
	line-height: 1.4;
	@include literal;
}


/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2,
.column2--thin {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.column3,
.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.sp-column1 {
	margin-left: 0;
	margin-right: 0;

	&> * {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
}

.sp-column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.sp-column3 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}



/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&> * {
		flex: 0 0 auto;
		width: 100%;
		
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		
		img {
			display: block;
			margin: 0 auto;
		}

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {
	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}


	&--right {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}
}


.sp-float.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 20px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 5px;
		}
	}

	&--circle {
		& > * {
			&::before {
				top: 0.75em;
				left: 7px;
				width: 4px;
				height: 4px;
				border-radius: 100%;
			}
		}
	}

	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--green {
		& > * {
			&::before {
				background: $baseColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 15px;
	background: #fff;

	&--border-green {
		border: 1px solid #72bab4;
	}
	&--green {
		background: #eaf5f5;
	}
	&--gray {
		background: #f3f3f3;
	}
	&--yellow {
		background: #f9f3d8;
	}
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
	&--graph-green {
		background: url(/common/img/common/bg_texture_green.png) 0 0 repeat;
		background-size: 15px;
		color: $white;
		@include radius(4);
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	border: 1px solid #dbdbdb;
	table-layout: fixed;
	font-size: $xs;
	line-height: 1.6;
	word-break: break-all;
	word-wrap: break-word;

	th,
	td {
		padding: 10px;
		background: #fff;
		border: 1px solid #dbdbdb;
		text-align: left;
		vertical-align: middle;
	}
	.bg-green {
		background: #007c71;
		color: $white;
	}
	.bg-green-lighten {
		background: #e0f3f3;
	}
	.bg-gray {
		background: #f6f6f6;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
	
	
	&--sp-column1 {
		display: block;
		
		thead,
		tbody {
			display: block;
		}
		tr {
			display: block;
			&:first-child th {
				border-top: none;
			}
		}
		th,
		td {
			display: block;
			padding: 15px;
			border-left: none;
			border-right: none;
			border-bottom: none;
		}
	}
	
	&--wide {
		border: none;
		display: block;

		th,
		td {
			display: block;
			padding: 5px 8px;
			border-bottom-width: 0;
			border-right-width: 0;
			text-align: center;
		}
		th{  
			width: auto;
			text-align: left;
		}
		thead {
			display: block;
			float: left;
			overflow-x: scroll;
		}
		tbody {
			display: block;
			width: auto;
			overflow-x: auto;
			white-space: nowrap;

			tr { 
				display: inline-block; 
				margin: 0 -3px;

				&:last-child {
					th,
					td {
						border-right-width: 1px;
					}
				}
			}
		}
		thead th:last-child,
		tbody td:last-child {
			border-bottom-width: 1px;
		}
	}
	
	&--list {
		border: none;

		tr {
			display:block;
			margin: 0 0 20px;
		}
		th {
			display: block;
		}
		td {
			display: list-item;
			margin: 0 0 0 20px;
			padding: 5px 0 0;
			border: none;
			background: none;
		}
	}
}


.table-wrap {
	width: 100%;
	padding: 0 0 5px;
	overflow-x: auto;
	
	.table {
		width: auto;
	}
	&::-webkit-scrollbar {
		height: 5px;
	}
	&::-webkit-scrollbar-track{
		background: #f2f2f2;
	}
	&::-webkit-scrollbar-thumb {
		background: #c2c2c2;
	}	
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 235px;
	height: 50px;
	padding: 0 30px;
	color: $textColor;
	font-size: $m;
	text-align: center;
	line-height: 1.4;
	@include radius(0);
	
	.icon-fix-left,
	.icon-fix-right {
		font-size: $xs;
		@include centering-elements(false, true);
	}
	.icon-fix-left {
		right: 10px;
	}
	.icon-fix-right {
		right: 10px;
	}
	
	&--green {
		background: $baseColor;
		color: $white;
	}
	&--yellow {
		background: #f5d862;
	}
	&--blue {
		background: #005979;
		color: $white;
	}
	&--red {
		background: #af1e26;
		color: $white;
	}
	&--red-lighten {
		background: #e13939;
		color: $white;
	}
	&--gray {
		background: #dedede;
	}
	&--lg {
		width: 100%;
		height: 60px;
	}
	&--sm {
		display: inline-flex;
		min-width: 0;
		min-height: 35px;
		padding: 0 20px;
		font-size: $xxs;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 10px;

		& > * {
			display: block;
			padding: 5px 12px;
			background: #f3f3f3;
			border: 1px solid #d5d5d5;
			color: $textColor;
			@include radius(3);
			
			&.current {
				background: $baseColor;
				border: 1px solid $baseColor;
				color: #fff;
				opacity: 1;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion {
	&.active {
		.icon-add::before {
			content: "\e909";
		}
	}
}
.accordion-hide,
.sp-accordion-hide {
	display: none;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 0;
	padding: 2px 10px;
	background: $baseColor;
	color: $white;
	font-size: $xxs;
	@include centering-elements(false, true);
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
}

input[type="text"],
textarea,
select {
	color: $textColor;
	font-size: 16px;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 26px;
	background: $baseColor;
	color: #fff;
	font-size: $xxxs;
	font-weight: $font-medium;
	text-align: center;
	
	@media screen and (max-width: 320px) {
		height: 22px;
	}

	&--red { background: $hsBaseColor; }
	&--blue { background: $jhsBaseColor; }
	&--pink,
	&--club,
	&--club-name {
		background: #e05c78;
	}
	&--club-name {
		background: #7a7a7a;
	}
	&--orange,
	&--exam-j {
		background: #dd6708;
	}

	&--purple,
	&--exam-h {
		background: #794c9e;
	}

	&--indigo,
	&--subject {
		background: #224296;
	}

	&--green,
	&--blog {
		background: $baseColor;
	}

	&--lime,
	&--life {
		background: #7db53d;
	}

	&--brown,
	&--other {
		background: #795548;
	}	&--yellow {
		background: #f3cd33;
		color: $textColor;
	}
	&--sm {
		width: 26px;
	}
	&--circle {
		@include circle(28);
	}
}


/* --------------------------------------------------- */
/* tag */
/* --------------------------------------------------- */
.tag {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 26px;
	margin: 0 1px 3px;
	padding: 0 10px;
	background: $white;
	border: 1px solid #c6e5e5;
	color: $baseColor;
	font-size: $xxxs;
	text-align: center;
	
	&--md {
		height: 35px;
	}
}



/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	
	&__item {
		&:not(:last-child) {
			margin: 0 0 20px;
		}
		&__date {
			display: block;
			margin: 0 0 5px;
			font-size: $xxs;
		}
		&__label {
			display: flex;
			margin: 0 0 5px;
			
			.label {
				min-width: 90px;
				width: auto;
				height: 22px;
				margin: 0 5px 0 0;
				padding: 0 5px;
				
				&--club-name {
					font-feature-settings: 'palt';
					letter-spacing: .05em;		
				}
			}
		}
		&__text {
		}
	}
}


/* --------------------------------------------------- */
/* faq */
/* --------------------------------------------------- */
.faq {
	
	&__question {
		display: flex;
		align-items: center;
		margin: 0 0 15px;
		padding: 10px 5px 10px 10px;
		background: #eaf5f5;
		cursor: pointer;
		line-height: 1.5;
		
		&::before {
			flex: 0 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			content: 'Q';
			margin: 0 8px 0 0;
			background: $baseColor;
			color: $white;
			font-size: $xxs;
			font-weight: bold;
			line-height: 1;
			@include circle(24);
		}
		&__text {
			flex: 1 1 auto;
		}
		.icon-add {
			margin: 0 0 0 5px;
			color: $baseColorDarken;
			font-size: $m;
		}

		&.active {
			.icon-add::before {
				content: "\e909";
			}
		}
	}
	
	&__answer {
		display: none;
		position: relative;
		padding: 0 0 30px 44px;
		
		&:last-child {
			padding-bottom: 0;
		}
		
		&::before {
			position: absolute;
			top: 0;
			left: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			content: 'A';
			background: #f3cd33;
			font-size: $xxs;
			font-weight: bold;
			line-height: 1;
			@include circle(24);
		}
	}
}


/* --------------------------------------------------- */
/* examination */
/* --------------------------------------------------- */
.examination {
	padding: 20px 0 0;
	border-width: 1px;
	border-style: solid;
	background: url(/common/img/common/bg_graph_paper.png) 0 0 repeat $white;
	background-size: 20px;
	text-align: center;

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto 5px;
		background: $white;
		border-style: solid;
		border-width: 1px;
		@include circle(30);

		& > *::before {
			display: block;
			content: '';
			height: 15px;
			background-position: 0 0;
			background-repeat: no-repeat;
			background-size: auto 15px;
		}
		.icon-junior-high-school::before {
			width: 18px;
			background-image: url(/common/img/index/ico_junior_high_school_2024.png);
		}
		.icon-high-school::before {
			width: 18px;
			background-image: url(/common/img/index/ico_high_school.png);
		}
	}
	&__ttl {
		margin: 0 0 20px;
		font-size: $m;
		font-weight: $font-medium;
	}
	.btn {
		padding: 0 10px;
		justify-content: flex-start;
		min-width: 0;
		width: 100%;
		margin: 0;
		font-size: $s;
		text-align: left;

		&:not(:last-child) {
			border-bottom: 1px solid $white;
		}
	}

	&--blue {
		border-color: #005979;
		&__icon { border-color: #005979; }
		&__ttl { color: #005979; }
	}
	&--red {
		border-color: #af1e26;
		&__icon { border-color: #af1e26; }
		&__ttl { color: #af1e26; }
	}

}


/* --------------------------------------------------- */
/* panel */
/* --------------------------------------------------- */
.panel {
	position: relative;
	margin-bottom: 35px;

	&__img {
		position: relative;
		width: 100%;
		padding-top: 76.4%;
		overflow: hidden;

		img {
			width: auto !important;
			max-width: 125%;
			max-height: 175%;
			@include centering-elements(true, true);
		}
		&__club {
			position: absolute;
			bottom: 8px;
			right: 8px;
			max-width: calc(100% - 16px);
			padding: 2px 8px;
			background: #7a7a7a;
			color: $white;
			font-size: $xxxs;
			font-feature-settings: 'palt';
			letter-spacing: 0.05em;
			line-height: 1.4;
		}
	}
	&__body {
		display: block;
		padding: 6px 10px 10px;
		background: $white;
		color: $textColor;
		
		&__date {
			margin: 0 0 5px;
			font-size: 1.1rem;
		}
		&__ttl {
			font-size: $xxs;
			font-weight: $font-medium;
			@include literal;

			.link-arrow__text {
				height: 5.5em;
			}
		}
	}

	&__icon {
		position: absolute;
		top: -13px;
		right: 0;
		display: flex;
	}
	.label--circle {
		position: absolute;
		right: 8px;
		bottom: 68px;
		
		@media screen and (max-width: 320px) {
			bottom: 60px;
		}
	}
}


/* --------------------------------------------------- */
/* movie */
/* --------------------------------------------------- */
.movie {
	position: relative;
	cursor: pointer;

	&::after {
		content: '';
		display: block;
		width: 50px;
		height: 50px;
		background: url(/common/img/common/ico_play.png) 0 0 no-repeat;
		background-size: 50px;
		@include centering-elements(true, true);
	}
}



/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.blog-list {
	&__item {
		padding: 15px 5px;
		border-bottom: 1px solid $borderColor;
		line-height: 1.5;

		&:first-child {
			padding-top: 0;
		}

		&__date {
			display: block;
			margin: 0 0 5px;
			font-size: $xxs;
		}
		&__category {
			display: flex;
			margin: 0 0 6px;
			
			.label {
				min-width: 90px;
				width: auto;
				height: 22px;
				margin: 0 5px 0 0;
				padding: 0 5px;
				
				&--club-name {
					font-feature-settings: 'palt';
					letter-spacing: .05em;		
				}
			}
		}
		&__text {
		}
	}
}

/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
/*
.entry {
	&__ttl {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 0 0 20px;
	}
	&__block {
		padding: 0 0 30px;
		border-bottom: 1px solid $borderColor;
	}
}
*/

/* --------------------------------------------------- */
/* entry-data */
/* --------------------------------------------------- */
.entry-data {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	margin: 0 0 20px;

	&__category {
		margin: 0 10px 0 0;
		padding: 4px 8px;
		background: $baseColor;
		color: #fff;
		font-size: $xxxs;
		line-height: 1.2;
	}

	&__date {
		display: block;
		font-size: $xxs;
		text-align: right;
		line-height: 1.2;
	}
	&__writen {
		flex: 0 0 100%;
		margin: 10px 0 0;
		text-align: right;
	}

}


/* --------------------------------------------------- */
/* entry-head */
/* --------------------------------------------------- */
.entry-head {
	&__label {
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;
		margin: 0 0 20px;
		
		& > * {
			margin: 0 0 0 3px;
		}
		.label + .tag {
			margin-left: 6px;
		}
	}
}


/* --------------------------------------------------- */
/* entry-body */
/* --------------------------------------------------- */
.entry-body {
	img {
		max-width: 100%;
		height: inherit;
	}
	
	strong {
		font-weight: bold;
		color: #dd6708;
	}

	em {
		font-weight: bold;
	}
	u {
		text-decoration: underline;
	}
	
}





/* ============================================================ */
/* .side */
/* ============================================================ */

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	line-height: 1.5;
	
	&__ttl {
		&__link {
			display: flex;
			align-items: center;
			height: 70px;
			padding: 20px;
			background: url(/common/img/common/bg_snav.png) 0 0 repeat;
			background-size: 30px;
			color: $white;
			font-size: $l;
			font-weight: $font-medium;
		}
	}
	&__item {
		border: 1px solid $borderColor;
		border-top: none;
		
		&__link {
			display: flex;
			align-items: center;
			padding: 15px 15px 15px 18px;
			color: $textColor;
			
			[class^="icon-angle-"] {
				margin: 0 8px 0 0;
				color: #ffd758;
			}
			.school_club .snav__item--school2 &,
			&.current,
			&:hover {
				background: #e4f5f5;
				opacity: 1;
			}
		}
	}
	&__child {
		&__item {
			border-top: 1px solid $borderColor;

			&__link {
				display: flex;
				align-items: center;
				padding: 13px 15px 13px 30px;
				background: #f7f7f7;
				color: $textColor;
				font-size: $xs;
				
				[class^="icon-angle-"] {
					margin: 0 8px 0 0;
					color: #ffd758;
				}
				&.current {
					font-weight: $font-medium;
				}
				&:hover {
					background: #e4f5f5;
					opacity: 1;
				}
			}
		}
	}
	
}

/* ============================================================ */
/* fix-menu */
/* ============================================================ */
.fix-menu {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 5;
	display: flex;
	height: 45px;
	justify-content: center;
	width: 100%;
	
	&__head {
		color: $white;
		text-align: center;
		font-size: 1.2rem;
		margin-bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		&._jhs_ {
			background: #005979;
		}
		&._hs_ {
			background: #AF1E26;
		}
		.__text {
			writing-mode: vertical-rl;
			transform: rotate(0.001deg);
		}
	}
	&__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		background: $baseColor;
		color: $white;
		font-size: 1.2rem;
		text-align: center;
		text-decoration: none;
		width:  30%;
		&:first-child {
			border-left: 0;
			width:  40%;
		}
		&._jhs_ {
			background: #16799D;
			border-right: 1px solid #005979;
		}
		&._hs_ {
			background: #D64F57;
			border-right: 1px solid #AF1E26;
		}
		&:last-child {
			border-right: none;
		}
	}
}