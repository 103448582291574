@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	max-width: 1320px;
	margin: 0 auto 50px;
	padding: 0 60px;
	
	@media screen and (max-width: 1279.5px) {
		padding: 0 30px;
	}
	
	
	&--column2 {
		.contents__inner {
			display: flex;
			justify-content: space-between;
			margin: 0 0 125px;
			
			@media screen and (max-width: 959.5px) {
				display: block;
			}
			
		}
		.main {
			flex: 1 1 0%;
			min-width: 0;
			margin: 0 50px 0 0;
			
			@media screen and (max-width: 959.5px) {
				margin: 0 0 40px;
			}
		}
		.side {
			flex: 0 0 auto;
			width: 230px;
			
			@media screen and (max-width: 959.5px) {
				width: 100%;
			}
		}
	}
}

.row {
	padding: 70px 0;
	
	&--red {
		background: url(/common/img/common/bg_dotted_red.png) 0 0 repeat #fff6f6;
		background-size: 4px;
	}
	&--green {
		background: url(/common/img/common/bg_dotted_green.png) 0 0 repeat #dbf1f1;
		background-size: 4px;
	}
	&--green-wide {
		position: relative;
		background: none;
			
		&::before {
			position: absolute;
			top: 0;
			bottom: 0;
			left: -500px;
			z-index: -1;
			content: '';
			width: 2000px;
			background: url(/common/img/common/bg_dotted_green.png) 0 0 repeat #dbf1f1;
			background-size: 4px;
		}
	}
}


/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 15px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.lead {
	color: $yellow;
	font-size: $xxl;
	font-weight: $font-medium;
	text-align: center;
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	margin: 0 0 75px;
	background: url(/common/img/common/bg_ttl01.png) 0 0 repeat;
	background-size: 88px;
	color: #0d6e6f;
	font-size: 3.6rem;
	font-weight: $font-medium;
	line-height: 1.2;
	@include literal;
	
	&__body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 1360px;
		height: 180px;
		margin: 0 auto;
		padding: 0 60px;
	}
	&__category-ttl {
		color: #676767;
		font-size: $xl;
		font-weight: $font-regular;
	}
}

.ttl02 {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 0 60px;
	padding: 0 0 40px;
	color: $baseColorDarken;
	font-size: 3.8rem;
	font-weight: $font-medium;
	text-align: center;
	line-height: 1.3;
	@include literal;
	
	&::after {
		bottom: 0;
		content: '';
		width: 60px;
		border-bottom: 3px solid $baseColor;
		@include centering-elements(true, false);
	}
	&__sub-ttl {
		color: $textColor;
		font-size: $xl;
		font-weight: $font-regular;
	}
	
	&--red {
		color: #dd0000;
		
		&::after {
			border-bottom-color: #dd0000;
		}
	}
}

.ttl03 {
	position: relative;
	margin: 0 0 40px;
	font-size: 2.8rem;
	font-weight: $font-medium;
	line-height: 1.4;
	text-align: center;
	@include literal;
	
	&::after {
		display: block;
		content: attr(data-text);
		margin: 10px 0 0;
		color: $baseColor;
		font-size: $xxs;
		font-weight: $font-regular;
		text-transform: uppercase;
		letter-spacing: 0;
	}
}

.ttl04 {
	margin: 0 0 40px;
	padding: 20px 0 0;
	border-top: 1px solid $baseColor;
	color: $baseColorDarken;
	font-size: $xxl;
	font-weight: $font-medium;
	line-height: 1.4;
	@include literal;
	
	&.accordion {
		display: flex;
		align-items: center;
		
		.icon-add {
			color: $baseColor;
			font-size: $xxxl;
		}
	}
}

.ttl05 {
	margin: 0 0 30px;
	padding: 12px 20px;
	background: #e0f3f3;
	font-size: $xl;
	font-weight: $font-medium;
	line-height: 1.4;
	@include literal;
	
	&.accordion {
		display: flex;
		align-items: center;
		
		.icon-add {
			color: $baseColor;
		}
	}
}

.ttl06 {
	margin: 0 0 30px;
	padding: 0 0 0 15px;
	border-left: 3px solid $baseColor;
	color: $baseColorDarken;
	font-size: $l;
	font-weight: $font-medium;
	line-height: 1.4;
	@include literal;
}

.ttl07 {
	position: relative;
	margin: 0 0 30px;
	padding: 40px 0 0;
	color: $baseColorDarken;
	font-size: 3.0rem;
	font-weight: $font-medium;
	text-align: center;
	line-height: 1.4;
	@include literal;
	
	&::before {
		top: 0;
		display: block;
		content: '';
		width: 50px;
		height: 5px;
		background: $baseColor;
		@include centering-elements(true, false);
	}
}

.ttl08 {
	margin: 0 0 30px;
	color: #ffdf7a;
	font-size: 3.0rem;
	font-weight: $font-medium;
	text-align: center;
	line-height: 1.4;
	@include literal;
}



/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	 margin-left: -20px;
	 margin-right: -20px;

		&> * {
			margin-left: 20px;
			margin-right: 20px;
			width: calc((100% - 81px) / 2);
		}
		&--thin {
		 margin-left: -5px;
		 margin-right: -5px;
			
			&> * {
				margin-left: 5px;
				margin-right: 5px;
				width: calc((100% - 21px) / 2);
			}
		}
 }

.column3 {
	margin-left: -15px;
	margin-right: -15px;

	&> * {
		margin-left: 15px;
		margin-right: 15px;
		width: calc((100% - 91px) / 3);
	}
	&--thin {
	 margin-left: -6px;
	 margin-right: -6px;

		&> * {
			margin-left: 6px;
			margin-right: 6px;
			width: calc((100% - 37px) / 3);
		}
	}
}

.column4 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 81px) / 4);
	}
	&--thin {
	 margin-left: -5px;
	 margin-right: -5px;

		&> * {
			margin-left: 5px;
			margin-right: 5px;
			width: calc((100% - 41px) / 4);
		}
	}
}

.column5 {
	margin-left: -5px;
	margin-right: -5px;

	&> * {
		margin-left: 5px;
		margin-right: 5px;
		width: calc((100% - 51px) / 5);
	}
}

.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 14.6%;
	}
}


/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;

	&> * {
		flex: 0 0 auto;

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			max-width: 50%;

			* {
				width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			max-width: 50%;

			* {
				width: 100%;
			}

		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 5px;
			height: 5px;
			display: block;
			position: absolute;
			top: 0.75em;
			left: 0;
		}
	}

	&--circle {
		& > * {
			&::before {
				left: 2px;
				width: 4px;
				height: 4px;
				border-radius: 100%;
			}
		}
	}
	
	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--green {
		& > * {
			&::before {
				background: $baseColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 25px 30px;
	box-sizing: border-box;
	background: $white;

	&--border-green {
		border: 1px solid #72bab4;
	}
	&--green {
		background: #eaf5f5;
	}
	&--gray {
		background: #f3f3f3;
	}
	&--yellow {
		background: #f9f3d8;
	}
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
	&--graph-green {
		background: url(/common/img/common/bg_texture_green.png) 0 0 repeat;
		background-size: 30px;
		color: $white;
		@include radius(4);
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	
	th,
	td {
		padding: 10px 15px;
		background: $white;
		border: 1px solid #dbdbdb;
		font-size: $s;
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
	}
	.bg-green {
		background: #007c71;
		color: $white;
	}
	.bg-green-lighten {
		background: #e0f3f3;
	}
	.bg-gray {
		background: #f6f6f6;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
}

.table--center {
	th,
	td {
		text-align: center;
	}
}

.table--thin {
	th,
	td {
		padding: 5px 10px;
		line-height: 1.4;
	}
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 200px;
	height: 50px;
	padding: 0 30px;
	border-style: solid;
	border-width: 1px;
	color: $textColor;
	font-size: $l;
	text-align: center;
	line-height: 1.4;
	cursor: pointer;
	@include radius(0);
	@include transition;

	.icon-fix-left,
	.icon-fix-right {
		font-size: $xl;
		@include centering-elements(false, true);
	}
	.icon-fix-left {
		left: 10px;
	}
	.icon-fix-right {
		right: 10px;
	}
	&:hover {
		text-decoration: none;
	}
	
	&--green {
		background: $baseColor;
		border-color: $baseColor;
		color: $white;
		
		&:hover {
			background: $white;
			color: $baseColor;
		}
	}
	&--yellow {
		background: #f5d862;
		border-color: #f5d862;
		
		&:hover {
			background: $white;
		}
	}
	&--blue {
		background: #005979;
		border-color: #005979;
		color: $white;
		
		&:hover {
			background: $white;
			color: #005979;
		}
	}
	&--red {
		background: #af1e26;
		border-color: #af1e26;
		color: $white;
		
		&:hover {
			background: $white;
			color: #af1e26;
		}
	}
	&--red-lighten {
		background: #e13939;
		border-color: #e13939;
		color: $white;
		
		&:hover {
			background: $white;
			color: #e13939;
		}
	}
	&--gray {
		background: #dedede;
		border-color: #dedede;
		
		&:hover {
			background: $white;
		}
	}
	&--lg {
		min-width: 300px;
		height: 65px;
	}
	&--sm {
		min-width: inherit;
		height: 40px;
		font-size: $m;
		
		.icon-fix-left,
		.icon-fix-right {
			font-size: $m;
		}
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 5px;

		& > * {
			display: block;
			padding: 10px 15px;
			background: #f3f3f3;
			border: 1px solid #d5d5d5;
			color: $textColor;
			@include radius(3);
			
			&.current,
			&:hover {
				background: $baseColor;
				border: 1px solid $baseColor;
				color: $white;
				text-decoration: none;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion {
	cursor: pointer;
	
	&.active {
		.icon-add::before {
			content: "\e909";
		}
	}
}

.accordion-hide {
	display: none;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 10px;
	padding: 2px 10px;
	background: $baseColor;
	color: $white;
	font-size: $xxs;
	@include centering-elements(false, true);
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	font-size: $m;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 95px;
	height: 26px;
	background: #ddd;
	color: $white;
	font-size: $xs;
	font-weight: $font-medium;
	text-align: center;
	line-height: 1.4;
	
	&--red { background: $hsBaseColor; }
	&--blue { background: $jhsBaseColor; }
	&--pink,
	&--club {
		background: #e05c78;
	}
	&--club-name {
		background: #7a7a7a;
	}

	&--orange,
	&--exam-j {
		background: #dd6708;
	}

	&--purple,
	&--exam-h {
		background: #794c9e;
	}

	&--indigo,
	&--subject {
		background: #224296;
	}

	&--green,
	&--blog {
		background: $baseColor;
	}

	&--lime,
	&--life {
		background: #7db53d;
	}

	&--brown,
	&--other {
		background: #795548;
	}
	&--yellow {
		background: #f3cd33;
		color: $textColor;
	}
	&--sm {
		width: 26px;
	}
	&--md {
		height: 30px;
	}
	&--circle {
		@include circle(40);
	}
}


/* --------------------------------------------------- */
/* tag */
/* --------------------------------------------------- */
.tag {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 26px;
	margin: 0 2px 6px;
	padding: 0 15px 0 10px;
	background: $white;
	border: 1px solid #c6e5e5;
	color: $baseColor;
	font-size: $s;
	text-align: center;
	line-height: 1.4;
	@include transition;
	
	&--md {
		height: 35px;
		font-size: 1.5rem;
	}
}
a.tag:hover {
	background: $baseColor;
	border: 1px solid $baseColor;
	color: $white;
	text-decoration: none;
}



/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	min-width: 0;
	
	&__item {
		display: flex;
		
		&:not(:last-child) {
			margin: 0 0 25px;
		}
		&__body {
			flex: 1 1 0%;
			min-width: 0;
		}
		&__date {
			flex: 0 0 auto;
			margin: 0 3% 0 0;
		}
		&__label {
			display: flex;
			margin: 0 0 6px;
			
			.label {
				min-width: 120px;
				width: auto;
				margin: 0 5px 0 0;
				padding: 0 8px;
				
				&--club-name {
					font-size: $xxs;
					font-feature-settings: 'palt';
					letter-spacing: .05em;		
				}
			}
		}
		&__text {
		}
	}
}



/* --------------------------------------------------- */
/* faq */
/* --------------------------------------------------- */
.faq {
	
	&__question {
		display: flex;
		align-items: center;
		margin: 0 0 20px;
		padding: 15px;
		background: #eaf5f5;
		cursor: pointer;
		
		&::before {
			flex: 0 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			content: 'Q';
			margin: 0 10px 0 0;
			background: $baseColor;
			color: $white;
			font-size: $l;
			font-weight: bold;
			line-height: 1;
			@include circle(34);
		}
		&__text {
			flex: 1 1 auto;
		}
		.icon-add {
			margin: 0 0 0 10px;
			color: $baseColorDarken;
			font-size: $xxl;
		}

		&.active {
			.icon-add::before {
				content: "\e909";
			}
		}
	}
	
	&__answer {
		display: none;
		position: relative;
		padding: 0 0 40px 60px;
		
		&:last-child {
			padding-bottom: 0;
		}
		
		&::before {
			position: absolute;
			top: 0;
			left: 15px;
			display: flex;
			align-items: center;
			justify-content: center;
			content: 'A';
			background: #f3cd33;
			font-size: $l;
			font-weight: bold;
			line-height: 1;
			@include circle(34);
		}
	}
}


/* --------------------------------------------------- */
/* examination */
/* --------------------------------------------------- */
.examination {
	padding: 0 40px 50px;
	border-width: 1px;
	border-style: solid;
	background: url(/common/img/common/bg_graph_paper.png) 0 0 repeat $white;
	background-size: 20px;
	text-align: center;
	height: 100%;

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: -50px auto 15px;
		background: $white;
		border-style: solid;
		border-width: 1px;
		@include circle(100);

		& > *::before {
			display: block;
			content: '';
			height: 56px;
			background-position: 0 0;
			background-repeat: no-repeat;
			background-size: auto 56px;
		}
		.icon-junior-high-school::before {
			width: 66px;
			background-image: url(/common/img/index/ico_junior_high_school_2024.png);
		}
		.icon-high-school::before {
			width: 66px;
			background-image: url(/common/img/index/ico_high_school.png);
		}
	}
	&__ttl {
		margin: 0 0 20px;
		font-size: $xxxl;
		font-weight: $font-medium;
	}
	.btn {
		width: 100%;
		margin: 0 0 10px;
	}

	&--blue {
		border-color: #005979;
		&__icon { border-color: #005979; }
		&__ttl { color: #005979; }
	}
	&--red {
		border-color: #af1e26;
		&__icon { border-color: #af1e26; }
		&__ttl { color: #af1e26; }
	}
	&__img {
		margin-bottom: 10px;
		@include transition;
		display: block;
		&:hover {
			opacity: 0.7;
		}
	}
}



/* --------------------------------------------------- */
/* panel */
/* --------------------------------------------------- */
.panel {
	position: relative;
	display: block;
	margin-bottom: 50px;
	
	&__img {
		position: relative;
		padding-top: 73%;
		background: #f2f2f2;
		overflow: hidden;
		
		&::before,
		&::after {
			position: absolute;
			content: '';
			opacity: 0;
			visibility: hidden;
			@include transition;
		}
		&::before {
			top: 5px;
			left: 5px;
			right: 5px;
			bottom: 5px;
			z-index: 1;
			border: 1px solid rgba($white, 0.4);
		}
		&::after {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba($baseColor, .75);
		}
		
		img {
			/*
			width: auto !important;
			min-width: 100%;
			min-height: 100%;
			*/
			@include centering-elements(true, true);
			@include transition;
		}
		&__read {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $white;
			font-size: $m;
			opacity: 0;
			@include transition;
		}
		&__club {
			position: absolute;
			bottom: 8px;
			right: 8px;
			max-width: calc(100% - 16px);
			padding: 4px 8px;
			background: #7a7a7a;
			color: $white;
			font-size: $xxs;
			font-feature-settings: 'palt';
			letter-spacing: 0.05em;
			line-height: 1.4;
		}
	}
	&__body {
		width: 100%;
		height: 115px;
		padding: 15px 20px;
		background: $white;
		color: $textColor;
		line-height: 1.6;
		@include transition;
		
		@media screen and (max-width: 1024px) {
			height: 85px;
			padding: 10px 20px;
		}
		&__date {
			margin: 0 0 5px;
			font-size: $xs;
		}
		&__ttl {
			font-weight: $font-medium;
			@include literal;

			.link-arrow__text {
				overflow: hidden;
				display: block;
				height: 3.2em;
			}

		}
	}

	&__icon {
		position: absolute;
		top: -15px;
		right: 0;
		z-index: 5;
		display: flex;
	}
	
	&:hover {
		text-decoration: none;
		
		.panel__img {
			img {
				transform: translate(-50%, -50%) scale(1.2);
			}
			&::before,
			&::after,
			&__read {
				opacity: 1;
				visibility: visible;
			}
		}
		.panel__body__date,
		.link-arrow__icon,
		.link-arrow__text {
			color: $baseColor;
		}
	}
	
	.label--circle {
		position: absolute;
		right: 10px;
		bottom: 92px;
	}
	
}


/* --------------------------------------------------- */
/* movie */
/* --------------------------------------------------- */
.movie {
	position: relative;
	cursor: pointer;
	@include transition;

	&::after {
		content: '';
		display: block;
		width: 55px;
		height: 55px;
		background: url(/common/img/common/ico_play.png) 0 0 no-repeat;
		background-size: 55px;
		@include centering-elements(true, true);
	}

	&:hover {
		@include opacity;
	}
}

#guide_jhs_application {
	.movie {
		&::after {
			width: 90px;
			height: 90px;
			background-size: 90px;
		}
	}
}



/* --------------------------------------------------- */
/* blog-list */
/* --------------------------------------------------- */
.blog-list {
	&__item {
		display: flex;
		padding: 15px 5px;
		border-bottom: 1px solid $borderColor;
		line-height: 1.5;

		&:first-child {
			padding-top: 0;
		}

		&__date {
			flex: 0 0 100px;
			font-size: $s;
		}
		&__body {
			flex: 1 1 auto;
		}
		&__category {
			display: flex;
			margin: 0 0 8px;

			.label {
				min-width: 120px;
				width: auto;
				margin: 0 5px 0 0;
				padding: 0 8px;
				
				&--club-name {
					font-size: $xxs;
					font-feature-settings: 'palt';
					letter-spacing: .05em;		
				}
			}
		}
		&__text {
			a:hover {
				text-decoration: underline;
			}
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
/*
.entry {
	&__ttl {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 0 0 40px;
	}
	&__block {
		padding: 0 0 50px;
		border-bottom: 1px solid $borderColor;
	}
}
*/

/* --------------------------------------------------- */
/* entry-data */
/* --------------------------------------------------- */
.entry-data {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-end;
	margin: 0 0 45px;
	line-height: 1.4;

	&__category {
		margin: 0 10px 0 0;
		padding: 4px 8px;
		background: $baseColor;
		color: #fff;
		font-size: $s;
		line-height: 1.2;
	}

	&__date {
		display: block;
		font-size: $m;
		text-align: right;
		line-height: 1.2;
	}
	&__writen {
		margin: 0 0 0 20px;
	}
}


/* --------------------------------------------------- */
/* entry-head */
/* --------------------------------------------------- */
.entry-head {
	margin: 0 0 60px;
	
	&__label {
		display: flex;
		justify-content: flex-end;
		
		& > * {
			margin: 0 0 0 5px;
		}
		.label + .tag {
			margin-left: 10px;
		}
	}
}

/* --------------------------------------------------- */
/* entry-body */
/* --------------------------------------------------- */
.entry-body {
	strong {
		font-weight: bold;
		color: #dd6708;
	}

	em {
		font-weight: bold;
	}

	a {
		font-weight: bold;
	}
	u {
		text-decoration: underline;
	}
}



/* ============================================================ */
/* .side */
/* ============================================================ */

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	line-height: 1.5;
	
	&__ttl {
		&__link {
			display: flex;
			align-items: center;
			height: 100px;
			padding: 20px;
			background: url(/common/img/common/bg_snav.png) 0 0 repeat;
			background-size: 30px;
			color: $white;
			font-size: $xl;
			font-weight: $font-medium;
		}
	}
	&__item {
		background: $white;
		border: 1px solid $borderColor;
		border-top: none;
		
		&__link {
			display: flex;
			align-items: center;
			padding: 15px 15px 15px 18px;
			color: $textColor;
			
			[class^="icon-angle-"] {
				margin: 0 8px 0 0;
				color: #ffd758;
			}
			.school_club .snav__item--school2 &,
			&.current,
			&:hover {
				background: #e4f5f5;
			}
		}
	}
	&__child {
		&__item {
			border-top: 1px solid $borderColor;

			&__link {
				display: flex;
				align-items: center;
				padding: 13px 15px 13px 30px;
				background: #f7f7f7;
				color: $textColor;
				font-size: $s;
				
				[class^="icon-angle-"] {
					margin: 0 8px 0 0;
					color: #ffd758;
				}
				&.current {
					font-weight: $font-medium;
				}
				&:hover {
					background: #e4f5f5;
				}
			}
		}
	}
	
}


/* ============================================================ */
/* 交通アクセス */
/* /access/ */
/* ============================================================ */
#access_index {
	
	.timezone {
		position: relative;
		margin: 0 0 20px;
		
		&__link {
			display: block;
			max-width: 900px;
			margin: 0 auto;
			padding: 0 0 15px;
		}
		
		&__transit {
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}
}

/* ============================================================ */
/* fix-menu */
/* ============================================================ */
.fix-menu {
	position: fixed;
	top: 50%;
	right: 0;
	display: flex;
	flex-direction: column;
	transform: translateY(-50%);
	z-index: 5;

	&__head {
		color: $white;
		text-align: center;
		font-size: $s;
		margin-bottom: 0;
		&._jhs_ {
			background: #005979;
		}
		&._hs_ {
			border-top: 1px solid $white;
			background: #AF1E26;
		}
	}
	&__btn {
		display: flex;
		flex-direction: column;
		width: 45px;
		padding: 15px 0;
		color: $white;
		font-size: 1.5rem;
		text-align: center;
		text-decoration: none;
		line-height: 45px;		
		@include transition;
		&:hover {
			text-decoration: none;
		}
		&._jhs_ {
			background: #16799D;
			border-bottom: 1px solid #005979;
			&:hover {
				background: #005979;
			}
		}
		&._hs_ {
			background: #D64F57;
			border-bottom: 1px solid #AF1E26;
			&:hover {
				background: #AF1E26;
			}
		}
		&:last-child {
			border-bottom: none;
		}
	}
	&__text {
		writing-mode: vertical-rl;
		transform: rotate(0.001deg);
	}	
}
