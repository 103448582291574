@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
	overflow-y: scroll;

	@media screen and (min-width:  767.5px) and (max-width:1024px) {
		font-size: calc(100vw / 102.4);
	}
}

body {
	font-family: 'Noto Sans Japanese', sans-serif;
}

.wrap {
	position: relative;
	display: flex;
	color: $textColor;
	font-size: 1.5rem;
	line-height: 1.8;
	overflow: hidden;
	
	@media screen and (max-width: 1279.5px) {
		display: block;
	}
	&__inner {
		@media screen and (min-width: 1280px) {
			width: calc(100% - 255px);
			padding: 80px 0 0;
		}
	}
}

.sp-view {
	display: none !important;
}

a {
	color: $baseColor;
	@include transition;
	
	&:hover {
		text-decoration: underline;
	}
	.header-logo &,
	.gnav &,
	&.sns-btn,
	.pagetop &,
	.snav &,
	.footer-sns & {
		&:hover {
			text-decoration: none;
		}
	}
}

.link-arrow {
	display: flex;
	
	&__icon {
		flex: 0 0 auto;
		width: 1em;
		padding-top: 0.25em;
		color: $yellow;
		text-align: center;
	}
	&__text {
		flex: 0 1 auto;
		color: $textColor;
	}
	&--ellipsis {
		.link-arrow__text {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}
}

.link-opacity {
	@include transition;
	
	&:hover {
		@include opacity;
	}
}

sup {
	position: relative;
	top: -0.1em;
	font-size: 75.5%;
	vertical-align: top;
}

sub {
	position: relative;
	top: 0.1em;
	font-size: 75.5%;
	vertical-align: bottom;
}



/* --------------------------------------------------- */
/* sns-btn */
/* --------------------------------------------------- */
.sns-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $white;
	font-size: $xl;
	@include circle(30);
	
	&--lg {
		font-size: 3.0rem;
		@include circle(50);
		
		&.sns-btn--youtube { font-size: 3.4rem; }
		&.sns-btn--youtube { font-size: 3.4rem; }
	}
	
	&--facebook { background: $facebook; }
	&--twitter { background: $twitter; }
	&--youtube { background: $youtube; }
	&--line { background: $line; }
}


/* --------------------------------------------------- */
/* bnr-link */
/* --------------------------------------------------- */
.bnr-link {
	display: flex;
	align-items: center;
	height: 60px;
	padding: 0 15px;
	border: 2px solid $baseColorLighten;
	color: $textColor;
	font-feature-settings: "palt";
	font-size: $s;
	font-weight: $font-medium;
	letter-spacing: 0.01em;
	line-height: 1.5;
	
	&__icon {
		margin: 0 8px 0 0;
		color: $baseColor;
		font-size: 2.6rem;
	}
	&--blog {
		height: auto;
		padding: 2px;
		border: 2px solid $baseColor;
		
		&__img {
		}
		
		&:hover {
			border-color: $baseColorLighten !important;
		}
	}
	&:hover {
		border-color: $baseColor;
		text-decoration: none;
		
		.bnr-link__text {
			color: $baseColorDarken;
		}
	}
	
	.contents__footer & {
		height: 75px;
	}
}


/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	margin: 0 0 60px;
	font-size: $xs;
	line-height: 1.4;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		&:not(:last-child) {
			&::after {
				margin: 0 6px;
				content: "\f105";
				font-family: 'icomoon';
			}
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 50px;
	bottom: 50px;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		background: $baseColor;
		color: $white;
		font-size: $l;
		@include transition;
		
		&:hover {
			@include opacity;
		}
	}
}



/* --------------------------------------------------- */
/* izimodal */
/* --------------------------------------------------- */
.modal {
	&__close {
		position: absolute;
		top: -20px;
		right: -40px;
		z-index: 10;
		color: $white;
		font-size: $xl;
		cursor: pointer;
		@include transition;
		
		&:hover {
			@include opacity;
		}
	}
}

