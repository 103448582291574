@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	border-top: 1px solid $borderColor;


	/* footer-sns
	----------------------------------------------------------- */
	.footer-sns {
		display: flex;
		max-width: 1240px;
		margin: 30px auto;
		padding: 0 20px;
		
		&__item {
			display: flex;
			justify-content: center;
//			width: 25%;
			width: 50%;
			border-right: 1px solid $borderColor;
			
			&:first-child {
				border-left: 1px solid $borderColor;
			}
			
			&__link {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				color: $textColor;
				line-height: 1.4;
			}
		}
	}
	
	
	/* fnav
	----------------------------------------------------------- */
	.fnav {
		padding: 50px 0 80px;
		background: url(/common/img/common/bg_fnav.png) 0 0 repeat;
		background-size: 30px;
		color: $white;
		line-height: 1.5;
		
		&__inner {
			display: flex;
			max-width: 1240px;
			margin: 0 auto;
			padding: 0 20px;
		}
		&__level1 {
			width: 25%;
			
			&__item {
				&:not(:last-child) {
					margin: 0 0 30px;
				}
				&__link {
					color: $white;
					font-size: $m;
					font-weight: $font-medium;
				}
			}
		}
		&__level2 {
			margin: 15px 0 0;
			
			&__item {
				&:not(:last-child) {
					margin: 0 0 3px;
				}
				&__link {
					color: #f0f0f0;
					font-size: $xs;
				}
			}
		}
		&__level3 {
			margin: 5px 0 0;
			
			&__item {
				&:not(:last-child) {
					margin: 0 0 3px;
				}
				&::before {
					content: '－';
					margin: 0 6px 0 0;
					color: #929191;
				}
				&__link {
					color: #f0f0f0;
					font-size: $xs;
				}
			}
		}
	}


	/* footer-content
	----------------------------------------------------------- */
	.footer-content {
		display: flex;
		align-items: center; 
		max-width: 1240px;
		margin: 30px auto;
		padding: 0 20px;
		
		&__logo {
			display: flex;
			align-items: center;
			margin: 0 25px 0 0;
			
			&__mark {
				width: 50px;
				margin: 0 15px 0 0;
			}
			&__text {
				width: 180px;
			}
		}
		&__address {
			font-size: $xs;
			line-height: 1.6;
		}
		&__copyright {
			align-self: flex-end;
			margin-left: auto;
			color: #8e8e8e;
			font-size: 1.1rem;
		}
	}
}