@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
@media screen and (max-width: 320px) {
	html {
		font-size: calc(100vw / 37.5);
	}
}

body {
	background: $white;
	font-family: 'Noto Sans Japanese', sans-serif;
}

.wrap {
	color: $textColor;
	font-size: $s;
	line-height: 1.8;
}

.pc-view {
	display: none !important;
}

a {
	color: $baseColor;
}

.link-arrow {
	display: flex;
	
	&__icon {
		flex: 0 0 auto;
		width: 1em;
		padding-top: 0.25em;
		color: $yellow;
		text-align: center;
	}
	&__text {
		flex: 0 1 auto;
		color: $textColor;
	}
	&--ellipsis {
		.link-arrow__text {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}
}

sup {
	position: relative;
	top: -0.1em;
	font-size: 75.5%;
	vertical-align: top;
}

sub {
	position: relative;
	top: 0.1em;
	font-size: 75.5%;
	vertical-align: bottom;
}


/* --------------------------------------------------- */
/* sns-btn */
/* --------------------------------------------------- */
.sns-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $white;
	font-size: $m;
	@include circle(30);
	
	&--facebook { background: $facebook; }
	&--twitter { background: $twitter; }
	&--youtube {
		background: $youtube;
		font-size: $xl;
	}
	&--line {
		background: $line;
		font-size: $xl;
	}
}


/* --------------------------------------------------- */
/* bnr-link */
/* --------------------------------------------------- */
.bnr-link {
	display: flex;
	align-items: center;
	height: 54px;
	padding: 0 10px;
	border: 2px solid $baseColorLighten;
	color: $textColor;
	font-feature-settings: "palt";
	font-size: $xxs;
	font-weight: $font-medium;
	line-height: 1.5;
	
	&__icon {
		margin: 0 8px 0 0;
		color: $baseColor;
		font-size: $l;
	}
	&--blog {
		height: auto;
		padding: 12px 12px 12px 15px;
		
		&__img {
			width: 67px;
			margin-left: auto;
		}
	}
}



/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	margin: 0 0 30px;
	font-size: $xxxs;
	line-height: 1.4;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		
		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
			}
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 15px;
	bottom: 55px;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		background: rgba($baseColor, 0.8);
		color: $white;
	}
}


/* --------------------------------------------------- */
/* izimodal */
/* --------------------------------------------------- */
.modal {
	&__close {
		position: absolute;
		top: -30px;
		right: 10px;
		z-index: 10;
		color: $white;
		font-size: $l;
		cursor: pointer;
	}
}

html.iziModal-isOverflow {
	overflow: visible !important;
}

#school_index {
	.iziModal-navigate > button {
		width: 45px !important;
	}
	.iziModal-navigate-prev{
		left: 0 !important;
		margin: 0 !important;
	}
	.iziModal-navigate-next{
		right: 0 !important;
		margin: 0 !important;
	}
	.iziModal {
		max-width: 80% !important;
	}
}
