@charset "utf-8";
/* ============================================================ */
/* 明法の学び　共通 */
/* /learning/ */
/* ============================================================ */
.learning {
	
	.four-c {
		display: flex;
		padding: 15px;
		
		&__img {
			width: 33%;
			margin: 0 20px 0 0;
		}
		&__body {
			width: 67%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			line-height: 1.5;
		}
		&__ttl {
			margin: 0 0 5px;
			font-size: $xl;
			font-weight: $font-medium;
			line-height: 1;
			
			& > *:first-child {
				color: $baseColor;
				font-size: 3.0rem;
				transform: translateY(2px);
			}
		}
		&__text {
			font-size: 1.1rem;
		}
	}
	
	.features {
		position: relative;
		
		&__point {
			position: absolute;
			top: 0%;
			left: 50%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			background: $baseColor;
			color: $white;
			font-size: 3.2rem;
			font-weight: $font-medium;
			line-height: 1;
			transform: translate(-50%, -50%);
			@include circle(70);
			
			& > *:first-child:not(:last-child) {
				color: #ffdf7a;
				font-size: $xs;
				font-weight: $font-regular;
			}
		}
		&__ttl {
			margin: 20px 0;
			color: $baseColor;
			font-size: 2.6rem;
			font-weight: $font-medium;
			text-align: center;
			line-height: 1.5;
		}
	}
	
}


/* ============================================================ */
/* 特色ある学習（高校） */
/* /learning/hs_feature.html */
/* ============================================================ */
#learning_hs_feature {
	.features-course {
		min-height: 500px;
		background: url(/common/img/learning/hs_feature/bg_feature01.png) left bottom no-repeat $white;
		
		&__public,
		&__private {
			margin: 30px 0 0 290px;
		}
	}
	
}


/* ============================================================ */
/* 高校カリキュラム */
/* /learning/hs_curriculum.html */
/* ============================================================ */
#learning_hs_curriculum {
	.feature {
		position: relative;
		
		&__ttl {
			z-index: 1;
			width: 29.7%;
			@include centering-elements(true, false);
		}
		&__body {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -3px;
		}
		&__item {
			position: relative;
			width: calc((100% - 13px) / 2);
			margin: 0 3px 6px;
			padding: 30px 35px 35px;
			background-position: 0 0;
			background-repeat: repeat;
			background-size: 30px;
			color: $white;
			@include radius(5);
			
			&__number {
				position: absolute;
				color: rgba($white, .3);
				font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
				font-size: 13rem;
				font-style: italic;
				letter-spacing: -0.03em;
				line-height: 0.8;
			}
			&__ttl {
				margin: 0 0 20px;
				font-size: $xxxl;
				font-weight: $font-medium;
				line-height: 1.3;
			}
			&__img {
				position: absolute;
			}
			
			&:nth-child(1) {
				background-image: url(/common/img/learning/hs_curriculum/bg_texture_green.png);
				
				.feature__item__number {
					top: 0;
					right: 25%;
					letter-spacing: -0.09em;
				}
				.feature__item__ttl {
					position: relative;
					z-index: 1;
					margin-right: 25%;
					@include hs-feature-text-shadow(#0d7878);
				}
				.feature__item__text {
					margin-right: 25%;
				}
			}
			&:nth-child(2) {
				background-image: url(/common/img/learning/hs_curriculum/bg_texture_pink.png);
				
				.feature__item__number {
					top: 0;
					right: -5px;
				}
				.feature__item__ttl {
					margin-left: 30%;
					@include hs-feature-text-shadow(#e2647f);
				}
				.feature__item__text {
					margin-left: 30%;
				}
			}
			&:nth-child(3) {
				background: url(/common/img/learning/hs_curriculum/bg_texture_orange.png);
				padding: 30px 35px 15%;
				.feature__item__number {
					bottom: 0;
					right: 0;
				}
				.feature__item__ttl {
					@include hs-feature-text-shadow(#ec7718);
				}
				.feature__item__img {
					position: absolute;
					bottom: -15px;
					left: 15px;
					z-index: 1;
					width: 42%;
				}
				
			}
			&:nth-child(4) {
				background: url(/common/img/learning/hs_curriculum/bg_texture_blue.png);
				
				.feature__item__number {
					top: 0;
					right: -5px;
				}
				.feature__item__ttl {
					@include hs-feature-text-shadow(#275da7);
				}
			}
			&:nth-child(5) {
				background: url(/common/img/learning/hs_curriculum/bg_texture_purple.png);
				
				.feature__item__number {
					bottom: 0;
					right: 0;
				}
				.feature__item__ttl {
					@include hs-feature-text-shadow(#625496);
				}
				.feature__item__img {
					width: 42.2%;
				}
			}
		}
	}
}


/* ============================================================ */
/* コース紹介 */
/* /learning/jhs_course.html */
/* ============================================================ */
#learning_jhs_course {
	.img-change {
		max-width: 650px;
		margin: 0 auto;
	}
}



/* ============================================================ */
/* 特色ある学習（中学） */
/* /learning/jhs_feature.html */
/* ============================================================ */
#learning_jhs_feature {
	.features-experience1,
	.features-experience2,
	.features-experience3 {
		.features__ttl {
			margin-bottom: 10px;
			font-size: 3.4rem;
		}
		.features__label {
			display: inline-flex;
			width: auto;
			height: 35px;
			padding: 0 10px;
			font-size: $s;
		}
	}
	.features-experience3 {
		.features__label {
			position: absolute;
			top: 25px;
			right: 30px;
		}
	}
}



/* ============================================================ */
/* 中学カリキュラム */
/* /learning/jhs_curriculum.html */
/* ============================================================ */
#learning_jhs_curriculum {
	.features-curriculum {
		min-height: 350px;
		&--01 {
			background: url(/common/img/learning/jhs_curriculum/pic_curriculum01_pc.png) left bottom no-repeat $white;
			background-size: 70%;
		}
		&--02 {
			background: url(/common/img/learning/jhs_curriculum/pic_curriculum02_pc.png) left bottom no-repeat $white;
			background-size: 70%;
		}
		&--03 {
			background: url(/common/img/learning/jhs_curriculum/pic_curriculum03_pc.png) left bottom no-repeat $white;
			background-size: 70%;
		}
		&--04 {
			background: url(/common/img/learning/jhs_curriculum/pic_curriculum04_pc.png) left bottom no-repeat $white;
			background-size: 70%;
		}
		&--05 {
			background: url(/common/img/learning/jhs_curriculum/pic_curriculum05_pc.png) left bottom no-repeat $white;
			background-size: 70%;
		}
		&--06 {
			background: url(/common/img/learning/jhs_curriculum/pic_curriculum06_pc.png) left bottom no-repeat $white;
			background-size: 70%;
		}
		
		&__body {
			margin: 30px 0 0 35%;
		}
	}

}


/* ============================================================ */
/* サイエンスGE紹介 */
/* /learning/jhs_ge.html */
/* ============================================================ */
#learning_jhs_ge {
	
	.meaning {
		position: relative;
		padding-top: 60px;
		
		&__initials {
			top: -50px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #f3cd33;
			font-size: 6.0rem;
			@include circle(100);
			@include centering-elements(true, false);
		}
	}
	
}
