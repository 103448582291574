@charset "utf-8";
/* ============================================================ */
/* 進学実績 */
/* /career/ */
/* ============================================================ */
#career_index {
	
	.achievement {
		display: flex;
		align-items: center;
		color: $yellow;
		font-weight: $font-medium;
		line-height: 1.5;
		
		&__school {
			flex: 1 1 auto;
			font-size: $m;
		}
		&__people {
			margin: 0 0 0 20px;
			font-size: $m;
			
			&__number {
				margin: 0 3px 0 0;
				font-size: $xxxl;
				font-weight: 600;
			}
		}
	}	
	
}

