@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	position: relative;
	z-index: 20;
	display: flex;
	border-bottom: 1px solid $borderColor;
	
	
	/* header-logo
	----------------------------------------------------------- */
	.header-logo {
		flex: 1 1 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 60px;
		
		&__link {
			display: flex;
			align-items: center;
			line-height: 1;
			
			&__mark {
				width: 4.6vw;
				margin: 0 5px 0 0;
			}
			&__text {
				width: 28vw;
			}
		}
	}


	/* hnav
	----------------------------------------------------------- */
	.hnav {
		flex: 0 0 auto;
		display: flex;
		
		&__item {
			flex: 0 0 auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 13.3vw;
			height: 60px;
			border-left: 1px solid $borderColor;
			line-height: 1.5;
			
			&__icon {
				margin: 0 0 3px;
				font-size: $l;
			}
			&__text {
				color: $textColor;
				font-size: $xxxs;
			}
			&--menu {
				width: 16.6vw;
				background: $baseColor;
				color: $white;
				cursor: pointer;
			}
		}
	}


	/* gnav
	----------------------------------------------------------- */
	.gnav {
		position: absolute;
		top: 60px;
		left: 0;
		z-index: 10;
		width: 100%;
		background: $baseColor;
		color: $white;
		opacity: 0;
		visibility: hidden;
		
		&__content1,
		&__content2 {
			&__item {
				display: flex;
				align-items: center;
				padding: 15px;
				border-bottom: 1px solid rgba($white, 0.2);
				color: $white;
				line-height: 1.5;
				
				& > * {
					flex: 0 0 auto;
				}
				&__text {
					flex: 1 1 auto;
				}
				&.active {
					.icon-plus-square::before {
						content: "\f146";
					}
				}
			}
			&__lower {
				display: none;
				background: #023c3d;
				
				&__link {
					display: block;
					padding: 15px 25px;
					border-bottom: 1px dotted rgba($white, 0.2);
					color: $white;
					
					&__ornament {
						padding: 0 6px 0 0;
						color: rgba($white, 0.5);
						font-size: $xxs;
						transform: translateY(-2px);
					}
				}
				&__ttl {
					padding: 15px 25px;
					border-bottom: 1px dotted rgba($white, 0.2);
					color: $white;
					font-size: $m;
					font-weight: $font-medium;
				}
			}
		}
		
		&__close {
			display: flex;
			align-items: center;
			height: 40px;
			padding: 0 15px;
			background: #333;
		}
		
		.show & {
			opacity: 1;
			visibility: visible;
		}
	}
	
	
}